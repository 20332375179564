<template>
  <section class="home-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <loader color="black"  v-if="!isAvailable" />

    <areas v-else
           :scroll="true"
           :areas="areas"
           :page="'home'"
    />

    <b-modal ref="error_modal" :title="modalTitle" hide-footer>
      <span v-t="'MODAL.PAGE.CONTENT'" />
    </b-modal>
  </section>
</template>

<script>
  import Areas from '@/components/areas/areas';
  import Loader from '@/components/loader/loader';
  import JsonLD from '@/service/jsonld';
  import Config from '@/service/config';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import { ScreenReadyMixin } from '@/plugins/mixin';
  import { getMetaIos } from '@/utils/generateDeepLink';
  import { amsClient } from '@/service/ams';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';
  import StockStore from '@/store/stock';

  export default {
    name: 'HomeContainer',
    components: {
      Areas,
      Loader,
    },
    mixins: [AuthVariableMixin, ScreenReadyMixin],
    props: [],
    data() {
      return {
        // context: {},
        areas: [],
        head: {},
        ads: {},
        loading: true,
        pageAds: [],
        prevPage: null,
        currentPage: null,
        reloadComponent: true,
        metadata: {},
      };
    },
    computed: {
      isAvailable() {
        return !this.loading; // && this.context && this.context.conf;
      },
      modalTitle() {
        return this.$t('MODAL.PAGE.TITLE');
      },
      homeScreen() {
        return StockStore.getHomeScreen();
      },
    },
    watch: {
      isAvailable: function(val) {
        if (val) {
          this.setScreenReady(this.head, this.screenRequestTime);
        }
      },
      homeScreen: {
        immediate: true,
        handler(screen) {
          this.areas = [];

          if (!screen) {
            return;
          }

          this.areas = screen.items;

          this.head = screen.head;
          this.setCurrentScreen(this.head);
          this.setScreenResponse(this.head);
          this.loading = false;
          this.fillHeader();
          this.addSchemaOrg();
        },
      },
    },
    /* beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.loading = true;

        if (!to.query.onlyregister || !to.query.onlyrestore) {
          vm.get2ndHome();
        }
      });
    }, */
    /* beforeRouteUpdate(to, from, next) {
      const prev = to && to.params && to.params.name ? to.params.name : null;
      const curr = from && from.params && from.params.name ? from.params.name : null;

      if (curr && prev && prev !== curr) {
        this.currentPage = null;
        JSON.pop();
        this.loading = true;
        this.get2ndHome();
      }

      next();
    }, */
    created() {
      // this.getHomeData();
    },
    mounted() {
      $stats.send('page_opened', {
        page_type: 'home',
      });
      // this.$bus.$on('re-load-ams-entry', this.get2ndHome);
    },
    beforeDestroy() {
      JsonLD.pop();
      // this.$bus.$off('re-load-ams-entry', this.get2ndHome);
    },
    methods: {
      onChangedUserState(event) {
        if (this.head) {
          let t = this.areas;
          this.areas = [];
          this.$nextTick(() => {
            this.areas = t;
          });
        }
      },
      get2ndHome() {
        this.areas = [];
        this.loading = true;

        amsClient.callAms(Config.get('amsEntryPoint')).then(data => {
          if (data.items.length) {
            this.getHomeData(data.items[0].link);
          } else {
            this.$router.replace({
              path: '/error',
            });
          }
        });
      },
      getHomeData(url = `/screen/home/`) {
        this.loading = true;

        if (_isPreviewMode()) {
          url += (url.indexOf('?') === -1 ? '?' : '&') + 'preview=1';
        }

        this.screenRequestTime = this.setScreenRequest({
          link: url,
          itype: 'screen',
        });

        amsClient
          .callAms(url)
          .then(data => {
            this.areas = data.items;
            this.ads = {};

            data.head.cached = !!data.cached;
            this.head = data.head;
            this.setCurrentScreen(this.head);
            this.setScreenResponse(this.head);
            this.loading = false;
            this.fillHeader();
            this.addSchemaOrg();
            // this.$stats.send('PageView', {}, { only: ['fbq'] });

            /* this.pageAds =
              this.head && this.head.ads && this.head.ads.placements
                ? this.head.ads.placements[0]
                : {}; */
          })
          .catch(err => {
            this.$plError.setError(err, 'network');
            this.$router.replace({ path: '/error' });
          });
      },
      fillHeader() {
        this.metadata = normaliseMetaTags(this.head);
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },
        });
      },

      showErrorModal(err, code) {
        if (err) {
          this.$refs.error_modal.show();
          code = 301;

          memoryStore.set('close_backdrop', true);
        }
        let res = code ? { content: `${code}` } : { content: '200' };
        this.metadata.head = Object.assign({}, this.metadata.head, {
          'meta[name="prerender-status-code"]': res,
        });
      },
      addSchemaOrg() {
        let data = {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: location.origin,
          potentialAction: {
            '@type': 'SearchAction',
            target: location.origin + '/portal/search?q={search_term_string}',
            'query-input': 'required name=search_term_string',
          },
        };
        JsonLD.push(data);
        JsonLD.update();
      },
    },
  };
</script>

<style lang="scss">
  @import './Home';
</style>
